













































































































































import NetworksApi from "@/includes/Api/Networks.api";
import { NetworkConfig, NetworkStat, SetNetworkConfigReq } from "@/includes/types/networks";
import NetworkChats from "@/components/networks/NetworkChats.vue";
import { getNetworkTitle } from "../../includes/logic/Networks/utils";
import { errorNotification } from "@/includes/NotificationService";
import {
  deleteNetwork,
  getNetworkConfig,
  removeGroupFromNetwork,
  setNetworkConfig
} from "@/includes/logic/Networks/logic";
import { InputSetups } from "@/mixins/input-setups";
import NetworksHelperMixin from "@/includes/logic/Networks/NetworksHelperMixin";
import NetworkStatistics from "@/components/networks/NetworkStatistics.vue";
import NetworkSummaryByGroup from "@/components/networks/NetworkSummaryByGroup.vue";
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";
import { ULTIMATE_TAG } from "@/includes/constants";

import PageTitle from "piramis-base-components/src/components/PageTitle.vue";
import ChipsInput from "piramis-base-components/src/components/Pi/fields/ChipsInput/ChipsInput.vue";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import Icon from "piramis-base-components/src/components/Icon/Icon.vue";

import { Component, Mixins } from "vue-property-decorator";

type LocalNetworkModel = Required<Pick<SetNetworkConfigReq, 'access_mode' | 'ignored_users' | 'global_command_admins' | 'groups'>>

@Component({
  data() {
    return {
      ULTIMATE_TAG
    }
  },
  methods: {
    getNetworkTitle
  },
  components: {
    NotAvailableOptionsOverlay,
    NetworkSummaryByGroup,
    NetworkStatistics,
    NetworkChats,
    PageTitle,
    ChipsInput,
    SelectInput,
    Icon
  }
})
export default class NetworkDashboard extends Mixins(InputSetups, NetworksHelperMixin) {
  networkStatistics: NetworkStat | null = null

  networkModel: LocalNetworkModel | null = null

  statIsLoading = false

  get readonlyNetwork(): boolean {
    return this.$store.getters.readonlyNetwork
  }

  get network(): NetworkConfig | null {
    return this.$store.state.networksState.currentNetwork
  }

  get networkId() {
    return this.$store.state.networksState.currentNetwork?.id
  }

  saveConfig() {
    if (this.networkModel) {
      this.$baseTemplate.loader.open()

      return setNetworkConfig(this.networkId, this.networkModel)
        .then(res => {
          if (res) {
            this.setLocalModel()

            return this.networkModel
          }
        })
        .finally(() => {
          this.$baseTemplate.loader.close()
        })
    }

    return Promise.resolve()
  }

  deleteNetworkVm() {
    this.$confirm({
      title: this.$t('network_confirm_remove_title').toString(),
      content: this.$t('network_confirm_remove_content').toString(),
      okText: this.$t('delete_title').toString(),
      onOk: () => {
        this.$baseTemplate.loader.open()

        deleteNetwork(this.networkId)
          .then(res => {
            if (res) {
              this.gotoNetworksList()
            }

            this.$baseTemplate.loader.close()
          })
      }
    })
  }

  onAddChats(chatIds: Array<number>) {
    if (this.networkModel) {
      this.networkModel.groups.push(...chatIds)

      return this.saveConfig()
        .then(res => {
          if (res) {
            return chatIds
          }
        })
    } else {
      return Promise.resolve()
    }
  }

  removeGroupFromNetworkVm(id:number) {
    removeGroupFromNetwork(id, this.networkId)
      .then(res => {
        if (res) {
          this.$baseTemplate.loader.open()

          getNetworkConfig(this.networkId)
            .then(res => {
              this.setLocalModel()

              if (res) {
                this.loadStatistics()
              }
            })
            .finally(() => {
              this.$baseTemplate.loader.close()
            })
        }
      })
  }

  setLocalModel() {
    if (this.network) {
      const { global_command_admins, ignored_users, access_mode, chats: groups } = this.network

      this.networkModel = { global_command_admins, ignored_users, access_mode, groups }
    }
  }

  loadStatistics() {
    this.statIsLoading = true

    if (this.network?.statistics_available) {
      NetworksApi.getNetworkStat('tg', this.networkId)
        .then(({ data }) => {
          this.networkStatistics = data
        })
        .catch(errorNotification)
        .finally(() => {
          this.statIsLoading = false
        })
    } else {
      this.statIsLoading = false
    }
  }

  created() {
    this.setLocalModel()

    this.loadStatistics()
  }
}
