var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.network)?_c('div',{staticClass:"networks-item-dashboard"},[_c('page-title',{attrs:{"title":_vm.$t('networkdashboard_page_title', [_vm.getNetworkTitle(_vm.network)])}}),_c('a-card',{attrs:{"title":_vm.$t('network_settings')},scopedSlots:_vm._u([{key:"extra",fn:function(){return [(!_vm.readonlyNetwork)?_c('a-button',{staticClass:"btn-success btn-wrap",on:{"click":_vm.saveConfig}},[_vm._v(" "+_vm._s(_vm.$t('save_button'))+" ")]):_vm._e()]},proxy:true}],null,false,359933543)},[_c('select-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.networkModel,
          'key': 'access_mode',
          'prefix': 'networks_',
          'disabled': _vm.readonlyNetwork,
          'options': _vm.accessModeOptions,
          'clearable': false,
        }
      }}}),_c('chips-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.networkModel,
          'key': 'ignored_users',
          'prefix': 'networks_',
          'disabled': _vm.readonlyNetwork,
          'settingWrapper': {
            'disabledValue': [ ],
            'customiseValue': [ ],
          }
        },
      }}}),_c('chips-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.networkModel,
          'key': 'global_command_admins',
          'prefix': 'networks_',
          'disabled': _vm.readonlyNetwork,
          'settingWrapper': {
            'disabledValue': [ ],
            'customiseValue': [ ],
          },
        }
      }}}),(!_vm.readonlyNetwork)?_c('div',{staticClass:"text-right"},[_c('a-button',{attrs:{"type":"danger","icon":"delete"},on:{"click":_vm.deleteNetworkVm}},[_vm._v(" "+_vm._s(_vm.$t('network_confirm_remove_title'))+" ")])],1):_vm._e()],1),_c('div',{staticClass:"my-5 flex flex-col sm:flex-row gap-5 text-center"},[_c('router-link',{staticClass:"w-full sm:w-1/2",attrs:{"to":{name: 'NetworkActiveModules', params: { networkId: _vm.networkId }}}},[_c('a-card',{staticClass:"text-2xl",attrs:{"hoverable":""}},[_c('icon',{attrs:{"icon-pack":"feather","icon":"icon-life-buoy","size":"1.5rem"}}),_vm._v(" "+_vm._s(_vm.$t('menu_activemodules_title'))+" ")],1)],1),_c('router-link',{staticClass:"w-full sm:w-1/2",attrs:{"to":{ name: 'NetworkTriggers' }}},[_c('a-card',{staticClass:" text-2xl",attrs:{"hoverable":""}},[_c('a-icon',{staticClass:"text-2xl",attrs:{"type":"thunderbolt"}}),_vm._v(" "+_vm._s(_vm.$t('menu_networktriggers_title'))+" ")],1)],1)],1),_c('network-chats',{staticClass:"mb-5",attrs:{"chats":_vm.network.chats_info,"readonly":_vm.readonlyNetwork,"on-add-chats":_vm.onAddChats},on:{"remove-chat-from-network":_vm.removeGroupFromNetworkVm}}),(_vm.network.statistics_available)?[(!!_vm.networkStatistics)?[_c('network-statistics',{attrs:{"statistics":_vm.networkStatistics}})]:_vm._e(),(_vm.statIsLoading)?_c('a-spin',{staticClass:"w-full py-10",attrs:{"tip":_vm.$t('community_dashboard_loader_tip_title')}}):_vm._e()]:_c('not-available-options-overlay',{attrs:{"tags":_vm.ULTIMATE_TAG,"description":_vm.$t('network_dashboard_stat_limit_description'),"with-button":false}})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }